import React, { FC, useMemo } from 'react';
import { graphql, Link } from 'gatsby';

import { DangerouslySetInnerHtml, Layout } from 'gatsby-theme-husky/src/layout';
import ProductTitle from 'gatsby-theme-husky/src/components/ProductTitle';
import ProductFeatures from 'gatsby-theme-husky/src/components/ProductFeatures';
import ProductDescription from 'gatsby-theme-husky/src/components/ProductDescription';
import ProductAdvantages from 'gatsby-theme-husky/src/components/ProductAdvantages';
import Button from 'gatsby-theme-husky/src/common/Button';
import ProductCarousel from 'gatsby-theme-husky/src/components/ProductCarousel';
import useScreenRecognition from 'gatsby-theme-husky/src/hooks/useScreenRecognition';
import Breadcrumbs from 'gatsby-theme-husky/src/components/Breadcrumbs';
import ReviewStars from 'gatsby-theme-husky/src/components/ReviewStars';
import ProductReview from 'gatsby-theme-husky/src/components/ProductReview';
import RelatedProducts from 'gatsby-theme-husky/src/components/RelatedProducts';

import { IProductPageProps } from 'gatsby-theme-husky/src/templates/ProductPage/model';
import 'gatsby-theme-husky/src/templates/ProductPage/ProductPageMain.scss';
import './ProductPageRTL.scss';

const ProductPage: FC<IProductPageProps> = ({ data: { umbracoProducts, relatedProducts } }) => {
  const {
    defaultCompositions,
    properties: {
      seoMetaTitle,
      seoCanonicalUrl,
      seoMetaDescription,
      seoMetaKeywords,
      seoExternalHreflangs,
      title,
      amountCondoms,
      productAdvantages,
      productDescriptionText,
      productFeatures,
      buttons,
      image,
      sku,
      turnBackPDPText,
      buyNowDisclaimer,
    },
    url,
  } = umbracoProducts;
  const { isMobile } = useScreenRecognition();

  const urlToCategoryLink = useMemo(() => {
    const urlToCategory = url.split('/').filter((link) => link);
    urlToCategory.pop();

    return `/${urlToCategory.join('/')}`;
  }, [url]);

  return (
    <Layout
      defaultCompositions={defaultCompositions}
      headerTransparent
      seo={{
        seoMetaDescription,
        seoMetaKeywords,
        seoMetaTitle,
        seoCanonicalUrl,
        seoExternalHreflangs,
      }}
    >
      {isMobile ? (
        <div className="product__breadcrumbs--mobile">
          <Link to={urlToCategoryLink}>
            <div className="icon-arrow-left" />
            <span>{turnBackPDPText}</span>
          </Link>
        </div>
      ) : (
        <Breadcrumbs url={url} className="product__breadcrumbs" />
      )}
      <div className="product-page">
        <div className="product-right-pack">
          <ProductTitle title={title} amountCondoms={amountCondoms} classes="productTitleOrder" />
          <ReviewStars className="product-reviews" sku={sku} />
          {buttons?.map((btn, index) => (
            <div className="product-buttons">
              <Button
                key={`${btn.properties.buttonText}${index}`}
                ariaLabel={btn.properties.buttonText}
                link={btn.properties.buttonLink[0].url}
                btnColorVariant="dark"
                className="product-buttons-styles"
              >
                {btn.properties.buttonText}
              </Button>
            </div>
          ))}
          <DangerouslySetInnerHtml html={buyNowDisclaimer} className="product-disclaimer" />
        </div>
        <div className="product-page__content">
          <div className="product-banner product-page__content-left">
            <ProductCarousel
              isMobile={isMobile}
              images={image}
              isFirstViewport
              classes="product-carousel"
            />
          </div>
          <div className="product-page__content-right">
            {productFeatures?.length ? <ProductFeatures productFeatures={productFeatures} /> : null}
            {productDescriptionText?.length ? (
              <ProductDescription productDescriptionText={productDescriptionText} />
            ) : null}
          </div>
        </div>
      </div>
      {productAdvantages?.length ? (
        <ProductAdvantages productAdvantages={productAdvantages} />
      ) : null}
      <ProductReview sku={sku} />
      <div className="related-products__wrapper">
        <RelatedProducts
          relatedProducts={relatedProducts}
          relatedProductsCardCtaButton={umbracoProducts.properties.relatedProductsCardCtaButton}
          relatedProductsCtaButtonShow={umbracoProducts.properties.relatedProductsCtaButtonShow}
          relatedProductsTitle={umbracoProducts.properties.relatedProductsTitle}
          relatedProductsCardCTAButtonAriaLabel={
            umbracoProducts.properties.relatedProductsCardCTAButtonAriaLabel
          }
          relatedProductsCtaButton={umbracoProducts.properties.relatedProductsCtaButton}
        />
      </div>
      <div
        className="yotpo yotpo-main-widget"
        data-product-id={sku}
        data-name={title}
        data-url={url}
      />
    </Layout>
  );
};

export const query = graphql`
  query($url: String!, $links: [String]) {
    umbracoProducts(url: { eq: $url }) {
      url
      defaultCompositions {
        ...DefaultCompositionsFragment
      }
      properties {
        seoMetaDescription
        seoMetaKeywords
        seoMetaTitle
        seoCanonicalUrl
        seoExternalHreflangs {
          hreflang: key
          href: value
        }
        image {
          properties {
            imageAltLabel
          }
          mobileImage: localImage {
            childImageSharp {
              fluid(maxWidth: 200, quality: 50) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          desktopImage: localImage {
            childImageSharp {
              fluid(maxWidth: 500, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        sku
        title
        amountCondoms
        turnBackPDPText
        productAdvantages {
          properties {
            productAdvantage
            productAdvantageComment
          }
        }
        buttons {
          properties {
            buttonText
            buttonLink {
              url
            }
          }
        }
        productDescriptionText {
          properties {
            descriptionText
            descriptionTitle
          }
        }
        productFeatures {
          properties {
            productFeaturesText {
              value
            }
            productTextTitle
          }
        }
        relatedProductsCardCtaButton
        relatedProductsCtaButtonShow
        relatedProductsTitle
        relatedProductsCardCTAButtonAriaLabel
        relatedProductsCtaButton {
          url
          name
        }
        buyNowDisclaimer
      }
    }
    relatedProducts: allUmbracoProducts(filter: { url: { in: $links } }) {
      nodes {
        url
        properties {
          image {
            properties {
              imageAltLabel
            }
            localImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          title
        }
      }
    }
  }
`;

export default ProductPage;
